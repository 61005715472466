<template>

<div class="profile">

	<div class="profile-text">

		<h2>{{ block.name }}</h2>

		<div class="profile-text-role">{{ block.role }}</div>

		<p>{{ block.text }}</p>

	</div>

	<div class="profile-image" v-bgimage="block.image"></div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.profile {
	display: flex;
	border-bottom: 1px solid #c9c9c9;
	padding-bottom: 40px;
	margin-bottom: 40px;
}

.is-mobile .profile {
	flex-direction: column-reverse;
}

.profile-text {
	flex-grow: 1;
	padding-right: 40px;
}

.is-mobile .profile-text {
	padding-right: 0px;
}

.profile-text h2 {
	font-weight: bold;
	font-size: 32px;
	line-height: 30px;
	color: #343434;
}

.is-tablet .profile-text h2 {
	font-size: 24px;
}

.is-mobile .profile-text h2 {
	font-size: 20px;
}

.profile-text-role {
	color: #1277d4;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 20px;
}

.is-tablet .profile-text-role {
	font-size: 20px;
}

.is-mobile .profile-text-role {
	font-size: 16px;
}

.profile-text p {
	font-size: 16px;
	line-height: 30px;
	color: #343434;
}

.is-mobile .profile-text p {
	font-size: 14px;
	line-height: 20px;
}

.profile-image {
	flex-shrink: 0;
	width: 300px;
	border-radius: 50%;
	border: 14px solid #b7b7b7;
	height: 300px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.is-tablet .profile-image {
	width: 200px;
	height: 200px;
	border-width: 10px;
}

.is-mobile .profile-image {
	width: 200px;
	height: 200px;
	margin: 0px auto 20px auto;
	border-width: 6px;
}

</style>
